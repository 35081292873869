.layout-dashboard {
    padding: 10px;
}
.layout-dashboard ul {
    list-style: none;
}
.layout-dashboard ul li {
    display: block;
    line-height: 60px;
    border-bottom: 1px solid #eee;
}
.layout-dashboard ul li:hover {
    background-color: #eee;
}

.layout-dashboard ul li i {
    display: inline-block;
    font-style: normal;
    font-size: 18px;
    width: 80px;
    margin-right: 20px;
    text-align: right;
}
.layout-dashboard ul li span {
    font-size: 18px;
    font-weight: 500;
    display: inline-block;
    color: #707070;
}
.layout-dashboard ul li button {
    padding: 10px 20px;
    font-size: 18px;
    outline: none;
    border: 0;
    background-color: #0690FC;
    color: #fff;
    cursor: pointer;
}