.layout-pricing {
    position: relative;
    display: flex;
    margin: auto;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
}
.pricing-card-box {
    width: 270px;
    padding: 10px;
    margin: 20px;
    background: #f9f9f9;
    border-radius: 9px;
    text-align: center;
    align-items: center;
    border: 1px solid #eee;
    cursor: default;
}
.pricing-card-box:hover h3 {
    background: transparent;
    color: #5222d0;
}
.pricing-card-box h1 {
    font-size: 40px;
    font-weight: 800;
    color: #07001f;
    margin-top: 10px;
}
.pricing-card-box h1.active {
    background: transparent;
    color: #ec615b;
}
.pricing-card-box h1 i {
    font-style: normal;
    font-size: 20px;
    font-weight: 400;
    margin-right: 8px;
}
.pricing-card-box h1 span {
    font-size: 20px;
    font-weight: 400;
    margin-left: 8px;
}
.pricing-card-box button {
    margin-top: 10px;
    padding: 10px 60px;
    border: 2px solid #5222d0;
    background-color: #5222d0;
    color:#fff;
    font-weight: 600;
    font-size: 18px;
    cursor: pointer;
    border-radius: 30px;
    transition: all 50ms ease;
}
.pricing-card-box button:hover {
    border: 2px solid #ec615b;
    background-color: #fff;
    color:#ec615b;
}
.pricing-card-box ul {
    margin: 10px 30px;
}
.pricing-card-box ul li {
    list-style: none;
    padding: 6px 12px;
    font-size: 18px;
    text-align: left;
    border-bottom: 1px solid #d5d5d5;
}