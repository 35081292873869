::placeholder{
    color: #C7C7CD;
    font-size: 16px;
    font-weight: 400;
}
.custom-label {
    color: #101030;
    font-size: 18px;
    font-weight: 500;
}
.custom-input {
    position: relative;
    color: #333;
    background: #eee;
    padding: 10px;
    font-family: 'sans-serif';
    font-size: 18px;
    font-weight: 400;
    width: 100%;
    height: 100%;
    line-height: 1;
    outline: none;
    border: 1px solid transparent;
    border-radius: 6px;
}
.custom-input.password {
    padding-right: 50px;
}
.custom-tooltip-box {
    position: absolute;
    display: inline-block;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
    width: 24px;
    height: 24px;
    z-index: 10;
}
.custom-tooltip-icon-button {
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    border: none;
    background: transparent;
    color: #A6A6A6; 
}
.custom-text-input-box {
    position: relative;
    display: block;
    height: 50px;
}
.custom-text-input-box  .custom-input{
    border: 1px solid #CCC;
    background: #FFF;
}
.custom-input-base:focus,
.custom-icon-input-box .custom-input:focus{
    border: 1px solid #2962FF;
}
.custom-icon-input-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #808080;
    height: 24px;
    width: 24px;
    margin: 0 10px;
    color: #ACACAC;
    z-index: 10;
    pointer-events: none;
}
.custom-icon-input-box {
    position: relative;
    height: 50px;
}
.custom-icon-input-box .custom-input {
    padding-left: 40px;
    border: 1px solid #CCC;
    background: #FFF;
}
.custom-input-group-field {
    position: relative;
}
.custom-input-alert-box {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 24px;
    line-height: 24px;
    color: inherit;
}
.custom-input-alert-icon {
    margin-right: 15px;
}
.custom-input-alert-text {
    font-size: 15px;
}