.layout-sider .top-section {
    margin-top: 5px;
    height: 50px;
    width: 100%;
    display: inline-flex;
    position: relative;
}
.layout-sider .top-section .logo {
    display: flex;
    cursor: pointer;
    height: 50px;
    width: 100%;
    color: #fff;
    align-items: center;
    opacity: 1;
}
.layout-sider.active .top-section .logo {
    opacity: 0;
    pointer-events: none;
}
.layout-sider .logo a {
    display: flex;
    text-decoration: none;
    text-transform: uppercase; 
    font-size: 30px;
    color: #fff;
    align-items: center;
}
.layout-sider .logo img {
    width: 45px;
    height: 45px;
    margin-right: 5px;
    overflow: hidden;
}
.layout-sider #toggle-menu-btn {
    position: absolute;
    display: inline-block;
    color: #fff;
    right: 0;
    top: 0;
    left: 90%;
    width: 50px;
    height: 50px;
    padding: 5px;
    text-align: center;
    line-height: 50px;
    cursor: pointer;
    overflow: hidden;
    transform: translateX(-50%);
}
.layout-sider.active #toggle-menu-btn {
    left: 50%;
}
.layout-sider ul li .search-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 23px;
    text-align: center;
    border-radius: 12px;
    z-index: 99;
    color: #fff;
}
.layout-sider.active ul li .search-icon:hover {
    background: #fff;
    color:#333;
}
.layout-sider ul li input {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    border: none;
    outline: none;
    background: #333;
    border-radius: 12px;
    display: block;
    box-sizing: border-box;
    padding-left: 50px;
    font-size: 18px;
    color: #fff;
}
.divider {
    width: 100%;
    height: 1px;
    border-radius: 1px;
    background: #333;
}
.layout-sider ul {
    margin-top: 20px;
}
.layout-sider ul li {
    position: relative;
    height: 50px;
    width: 100%;
    list-style: none;
    line-height: 50px;
    margin-top: 3px;
}
.layout-sider ul li a {
    position: relative;
    display: block;
    height: 50px;
    width: 100%;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    white-space: nowrap;
    overflow: hidden;
}
.layout-sider ul li a.active{
    background: #666;
}
.layout-sider ul li a:hover {
    color: #11101d;
    background: #fff;
}
.layout-sider ul li a i {
    position: relative;
    display: inline-block;
    height: 50px;
    width: 50px;
    border-radius: 5px;
    line-height: 50px;
    font-size: 23px;
    text-align: center;
}
.layout-sider ul li a span {
    position: relative;
    overflow: hidden;
}
.layout-sider ul li .tooltip {
    position: absolute;
    left: 122px;
    top: 0;
    transform: translate(-50%, -50%);
    border-radius: 6px;
    height: 35px;
    width: 122px;
    background: #fff;
    line-height: 35px;
    text-align: center;
    box-shadow: 0 5px 10px rgba(0,0,0,0.2);
    transition: 0s;
    opacity: 0;
    pointer-events: none;
    display: none;
    overflow: hidden;
}
.layout-sider ul li:hover .tooltip {
    transition: all 0.5s ease;
    opacity: 0;
    top: 50%;
}
.layout-sider .main-menu span {
    overflow: hidden;
}