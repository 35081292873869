.layout-course {
    padding: 10px;
}
.layout-course ul {
    list-style: none;
    display: block;
}
.layout-course ul li {
    display: inline-block;
    list-style: none;
    vertical-align: top;
    margin: 10px;
    text-align: center;
}

.layout-course ul li a {
    display: block;
    text-decoration: none;
    font-size: 20px;
    color: #000;
    width: 100px;
    height: 100px;
    background-color: #eee;
}
.layout-course ul li a:hover {
    background-color: #2481CC;
    color: #fff;
}
.layout-course ul li a i {
    display: block;
    font-size: 30px;
    line-height: 60px;
}
.layout-course ul li a span {
    font-size: 18px;
}