* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
ul {
    margin: 0;
    padding: 0;
}
li {
    list-style: none;
}
/* body {
    background-color: #eee;
    font-family: Sans-Serif;
} */
