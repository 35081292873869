.layout-about-container {
    justify-content: center;
    padding: 10px;
}
.layout-about-box{
    border: 1px solid #eee;
    border-radius: 3px;
    margin:10px;
    padding: 10px;
    width: 160px;
    text-align: center;
    align-content: center;
    justify-content: center;
    display: inline-block;
}
.layout-about-box i {
    display: block;
    margin-bottom: 12px;
    font-size: 38px;
}
.skype{
    color:#00aff0;
}
.whatsapp{
    color:#2AC54D;
}
.telegram{
    color:#249CD8;
}
.email{
    color:#CBCBCB;
}
.layout-about-box .title {
    color: #202124;
    font-size: 26px;
    font-weight: bold;
    line-height: 32px;
    margin-bottom: 10px;
}
.layout-about-box .tag {
    color: #7D7F81;
    font-size: 15px;
    height: 18px;
    line-height: 18px;
}
.layout-about-box a {
    display: inline-block;
    text-decoration: none;
    background: #2481CC;
    font-size: 14px;
    line-height: 17px;
    height: 42px;
    border-radius: 22px;
    margin-top: 20px;
    padding: 13px 24px;
    color: #fff;
}
.layout-about-box a:hover{
    background: #1A8AD5;
}