.primary-download {
    /* max-width: 860px; */
    text-align: center;
    padding: 20px 0;
}
.primary-download .logo {
    display: block;
    width: 96px;
    height: 96px;
    background-image: url(/public/leaf.png);
    background-repeat: no-repeat;
    margin: 0 auto;
}
.primary-download .title {
    color: #4D4D4D;
    font-size: 36px;
    margin-bottom: 30px;
}
ul.download-menu {
    list-style: none;
}
ul.download-menu li.download-item {
    list-style-type: none;
}
.download-menu {
    display: block;
}
.download-item {
    display: inline-block;
    margin: 10px;
}
.download-item .download-title {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
    text-align: center;
    justify-content: center;
    align-items: center;
}
.download-item .download-title i {
    width: 40px;
    font-size: 30px;
}
.download-item .download-title span {
    margin-left: 5px;
    font-size: 18px;
}
.download-item .download-btn {
    display: block;
    text-decoration: none;
    padding: 16px 28px;
    background-color: #25D366;
    color: #000;
}
.download-item .download-btn span {
    font-size: 16px;
}
.download-item .download-btn i {
    width: 30px;
    font-size: 16px;
}
.fa-windows {
    color: #00A2ED;
}
.fa-apple {
    color: #A2AAAD;
}
.fa-android {
    color: #32DE84;
}