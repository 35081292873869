.layout-sider {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #000;
    width: 240px;
    height: 100%;
    box-sizing: border-box;
    padding: 6px 14px;
    overflow: hidden;
    overflow-y: overlay;
    transition: all .5s ease;
}
.layout-sider.active {
    width: 78px;
}
.layout-sider::-webkit-scrollbar {
    width: 5px;
}
.layout-sider::-webkit-scrollbar-thumb{
    border-radius: 5px;
    background-color: #eee;
}
.layout-container {
    position: absolute;
    background: #fff;
    top: 0;
    left: 240px;
    width: calc(100% - 240px);
    transition: all .5s ease;
}
.layout-container.active{
    left: 78px;
    width: calc(100% - 78px);
}
.layout-header {
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 64px;
    width: 100%;
    background: #fff;
    box-shadow: 0 1px 4px rgba(0,21,41,.08);
}
.layout-content {
    position: relative;
    display: block;
    width: 100%;
    padding: 20px;
}