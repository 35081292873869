.fonticon_au {
    background-image: url(/public/flags/flag_au.png);
}

.fonticon_de {
    background-image: url(/public/flags/flag_de.png);
}

.fonticon_fr {
    background-image: url(/public/flags/flag_fr.png);
}

.fonticon_se {
    background-image: url(/public/flags/flag_se.png);
}

.fonticon_gb {
    background-image: url(/public/flags/flag_gb.png);
}

.fonticon_jp {
    background-image: url(/public/flags/flag_jp.png);
}

.fonticon_nl {
    background-image: url(/public/flags/flag_nl.png);
}

.fonticon_sg {
    background-image: url(/public/flags/flag_sg.png);
}

.fonticon_us {
    background-image: url(/public/flags/flag_us.png);
}

.fonticon_ca {
    background-image: url(/public/flags/flag_ca.png);
}

.fonticon_hk {
    background-image: url(/public/flags/flag_hk.png);
}

.fonticon_cn {
    background-image: url(/public/flags/flag_cn.png);
}

.fonticon_sk {
    background-image: url(/public/flags/flag_sk.png);
}

input.custom_radio_checkbox {
    box-sizing: border-box;
    display: none;
}

input.custom_radio_checkbox+label:hover {
    border: 1px solid #1e88e5;
}

.custom_radio_checkbox_label {
    position: relative;
    vertical-align: top;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 270px;
    height: 89px;
    padding: 0px 0px 0px 80px;
    margin: 0px 18px 23px 0px;
    border: 1px solid #e6e9eb;
    border-radius: 3px;
    cursor: pointer;
    white-space: nowrap;
}

input.custom_radio_checkbox:checked+label.custom_radio_checkbox_label {
    background: linear-gradient(45deg, #135fac 1%, #1e88e5 64%, #40baf5 97%);
    border-width: 0px;
    box-shadow: 0 5px 30px -5px rgba(37, 45, 51, 0.5);
}

.custom_radio_checkbox_icon {
    position: absolute;
    display: inline-block;
    top: 50%;
    left: 20px;
    margin-top: -18px;
    width: 54px;
    height: 36px;
    line-height: 48px;
    border-radius: 3px;
    vertical-align: middle;
    opacity: 1;
    transition: opacity ease-in 0.25s;
}

input.custom_radio_checkbox:checked+label .custom_radio_checkbox_icon {
    opacity: 0;
}

.custom_radio_checkbox_checkmark {
    position: absolute;
    display: block;
    font-size: 24px;
    color: #fff;
    bottom: 2px;
    top: 50%;
    left: 20px;
    margin-top: -24px;
    background-color: #1e88e5;
    width: 48px;
    height: 48px;
    line-height: 48px;
    border-radius: 24px;
    text-align: center;
    opacity: 0;
    transition: opacity ease-in 0.25s;
}

input.custom_radio_checkbox:checked+label .custom_radio_checkbox_checkmark {
    opacity: 1;
}

.custom_radio_checkbox_line1 {
    display: block;
    overflow: hidden;
    word-wrap: break-word;
    white-space: normal;
    width: 156px;
    font-size: 15px;
    padding-top: 2px;
    line-height: 23px;
    color: #363b40;
    font-weight: bold;
    max-height: 72px;
    overflow: hidden;
}

.custom_radio_checkbox_line2 {
    display: block;
    width: 160px;
    color: #363b40;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    opacity: 0.8;
}

input.custom_radio_checkbox:checked+label .custom_radio_checkbox_line1 {
    background: initial;
    color: #ffffff;
}

input.custom_radio_checkbox:checked+label .custom_radio_checkbox_line2 {
    background: initial;
    color: #ffffff;
    opacity: 0.8;
}